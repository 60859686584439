<template>
  <table-container :list="typeList" height="750">
    <template v-show="typeList.lenght > 0">
      <el-table-column type="index" label="序号" fixed align="center">
      </el-table-column>
      <el-table-column
        prop="name"
        label="型号"
        width="150"
        fixed
        align="center"
      >
      </el-table-column>
      <template v-for="item of headerList">
        <el-table-column
          :key="item.id"
          :prop="String(item.id)"
          :label="item.title"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="specStatus(item.id, typeList[scope.$index].list)">
              <el-row>
                <el-col :span="12">
                  {{ item.id | specStatus(typeList[scope.$index].list) }}
                </el-col>
                <el-col :span="12">
                  <el-button
                    type="primary"
                    icon="el-icon-edit"
                    plain
                    @click="
                      handleEdit(
                        typeList[scope.$index].id,
                        item.id,
                        typeList[scope.$index].list
                      )
                    "
                  ></el-button>
                </el-col>
              </el-row>
            </template>
            <template v-else>
              <el-button
                type="danger"
                icon="el-icon-plus"
                plain
                @click="handleAdd(typeList[scope.$index].id, item.id)"
              ></el-button>
            </template>
          </template>
        </el-table-column>
      </template>
    </template>
  </table-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "List",
  props: {
    headerList: Array,
    typeList: Array,
  },
  data() {
    return {
      spec_id: "",
    };
  },
  computed: {
    ...mapState(["supplierList"]),
  },
  created() {},

  methods: {
    //新增
    handleAdd(typeId, prodId) {
      this.$emit("add", typeId, prodId);
    },
    //删除
    handleDel(typeId, prodId) {
      this.$emit("del", typeId, prodId);
    },
    //编辑
    handleEdit(typeId, prodId, list) {
      const item = this.specStatus(prodId, list, "item");
      // this.spec_id = item.spec_id;
      this.$emit("edit", typeId, prodId, item.spec_id);
    },
    //筛选单元格状态
    specStatus(id, list, type = "bool") {
      const item = list.find((item) => {
        return String(item.id) === String(id);
      });
      if (type === "bool") {
        return item.status;
      } else {
        return item;
      }
    },
  },
  filters: {
    //筛选单元格状态
    specStatus(id, list) {
      const item = list.find((item) => {
        return String(item.id) === String(id);
      });
      return item.supplier.name;
    },
  },
};
</script>
<style lang="less" scoped>
</style>